var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("avue-form", {
        ref: "form",
        staticStyle: { padding: "10px" },
        attrs: {
          option: _vm.formColumn,
          "upload-after": _vm.uploadAfter,
          "upload-preview": _vm.uploadPreview,
        },
        model: {
          value: _vm.data,
          callback: function ($$v) {
            _vm.data = $$v
          },
          expression: "data",
        },
      }),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }