<template>
<basic-container>
  <avue-form ref="form" v-model="data" :option="formColumn" :upload-after="uploadAfter" :upload-preview="uploadPreview" style="padding:10px;"></avue-form>
  <fileView ref="fileView" title="附件预览"></fileView>
</basic-container>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import FormLayout from "@/views/components/layout/form-layout";
import {dateFormat} from "@/util/date";
import {mapGetters} from "vuex";

export default {
  components: {
    FormLayout
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    formColumn() {
      return {
        labelWidth: 100,
        submitBtn: false,
        emptyBtn: false,
        gutter: 10,
        group: [],
        pageType: 'add',
        column:[
        {
          label: "作业票扫描件",
          prop: "ticketFile",
          span: 24,
          labelWidth: '170',
          disabled: !this.disabled,
          // listType: "picture-card",
          type: "upload",
          accept: '.doc,.docx,.pdf, .png, .jpg, .xls, .xlsx',
          tip: '支持扩展名: .doc,.docx,.pdf, .png, .jpg, .xls, .xlsx',
          action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
          propsHttp: {
            res: "data",
            url: "link",
          },
        },
        ]
      }

    }
  },
  methods:{
    uploadPreview(file,column,done){
      this.$refs.fileView.showFile(file.url)
    },
    uploadAfter(res, done, loading,column) {
      this.data.ticketFile.push({
        label:res.originalName,
        value:res.link,
        name: res.originalName,
        link: res.link,
        url: res.link,
      })
      loading()
    }
  },
  data() {
    return {
      data: {
        ticketFile: []
      },
    };
  },
};
</script>
